import React from 'react';
// import { Component } from 'react';
import { Link } from 'react-router-dom';

import { connect } from "react-redux";

import { withRouter } from 'react-router-dom';

import { Layout, Menu } from 'antd';
import {
  SearchOutlined,
  PlusOutlined,
  MenuOutlined,
  SettingOutlined,
  InfoCircleOutlined,
  UserOutlined
} from '@ant-design/icons';


const { Sider } = Layout;


/**
 * This component is used to render the side bar used for page navigation. It is
 * inspired by the example given in the documentation:
 *   https://ant.design/components/layout/
 */
class AntNav extends React.Component {
  //tracks if the side bar is collapsed or not
  state = {
    collapsed: false,
  };

  //Sets the collapsed state
  onCollapse = collapsed => {
    //console.log(collapsed);
    this.setState({ collapsed });
  };

  //Used to try to get the location of the window when the component is loaded
  componentDidMount()
  {
    this.setState({url: window.location.pathname})
  }

  //Used to update our props with the new pathname when the window locaion changes. This ensures we always
  //have the correct tab highlighted
  componentDidUpdate (prevProps, prevState) {
    if(prevState.url !== window.location.pathname)
      this.setState({url: window.location.pathname})
  }


  render() {

    if(!this.props.auth.isAuthenticated)
    {
      return (
        null
      )
    }
    const { collapsed } = this.state;

    let initialKey = "-1"; //FIXME: DO BETTER w/ 404


    //Lets us set what tab is being highlighted
    let url = this.state.url || window.location.pathname;

    switch (url){
      case "/search":
        initialKey = "1";
        break;
      case "/add":
        initialKey = "2";
        break;
      case "/list":
        initialKey = "3";
        break;
      case "/settings":
        initialKey = "4";
        break;
      case "/user":
      case "/login":
      case "/register":
      case "/dashboard":
        initialKey = "5";
        break;
      case "/about":
        initialKey = "6";
        break;

      default:
        if(url.indexOf("/page") === 0) //FIXME: DOES NOT ALWAYS WORK
          initialKey = "3";
        else if(url.indexOf("/edit") === 0)
          initialKey = "2";
        else
          return null;

    }
    //FIXME: DELETE USER NOTES ON LOGOUT (FROM STORE)!!!!!
    //FIXME: REMOVE LAST BEFORE FLIGHT
    return (



        <Sider theme="light" collapsible collapsed={collapsed} onCollapse={this.onCollapse} style={{ 'zIndex':'1', height: '100vh', position: 'relative', left: 0}}>
          <Menu theme="light" selectedKeys={initialKey} mode="inline">
            <Menu.Item key="1" icon={<SearchOutlined />}>
              <Link to="/search">Search</Link>
            </Menu.Item>
            <Menu.Item key="2" icon={<PlusOutlined />}>
              <Link to="/add">Add</Link>
            </Menu.Item>
            <Menu.Item key="3" icon={<MenuOutlined />}>
              <Link to="/list">List</Link>
            </Menu.Item>

            <Menu.Item key="5" icon={<UserOutlined />}>
              <Link to="/dashboard">User</Link>
            </Menu.Item>
            <Menu.Item key="4" icon={<SettingOutlined />}>
              <Link to="/settings">Settings</Link>
            </Menu.Item>
            {
              /*
            <Menu.Item key="6" icon={<InfoCircleOutlined />}>
               <Link to="/about">About</Link>
            </Menu.Item>
            */
            }
          </Menu>
        </Sider>


    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(connect(mapStateToProps)(AntNav));
