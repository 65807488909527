import '../../web.css';
import React from 'react';
import { Component } from 'react';

import {Helmet} from "react-helmet";

import { HashLink as Link } from 'react-router-hash-link';

import WebNav from '../WebNav'
import WebFooter from '../WebFooter'

//FIXME: CHANGE URL TO BE CREATING?
class AddingNotesFAQ extends Component {

  render()
  {
    return (
      <body className="webcontainer">
        <Helmet>
          <title>XING | FAQ - Creating Notes</title>
        </Helmet>
      <WebNav url="/faq/addingnotes"/>

      <section className="faqsection">
    		<aside>
    			<nav>
    				<h3>XING | FAQ</h3>
    				<ul>
    					<li><Link className="active" to="/faq/addingnotes#">Creating Notes</Link></li>
    					<li><Link to="/faq/searching#">Searching Notes</Link></li>
    					<li><Link to="/faq/editing#">Editing Notes</Link></li>
    					<li><Link to="/faq/merging#">Merging Notes</Link></li>
    				</ul>
    			</nav>
    		</aside>
    		<main className="faqcontent">
    			<h1>XING | FAQ - Creating Notes</h1>
    			<p>
    				In XING, notes are user created pages that can only be viewed by their creator. Each note consists of: a title, list of tags, and content. Each of these fields must be filled out by the user; however, there are some best practices for each.
    			</p>
    			<h4>Note Titles</h4>
    			<p>
    				Note titles are displayed at the top of each note as well as in search results. Because of this, a note’s title should act as a short overview of the note’s content that makes it easier to recognize.
    			</p>
    			<h4>Note Tags</h4>
    			<p>
    				Note tags are the primary keywords used in calculating search results. Because of this, tags should include unique words that would likely be in a search for the respective note.
    			</p>
    			<h4>Note Contents</h4>
    			<p>
    				A note’s content is the actual information contained in the note. Unlike the title and tags, a note’s content can be styled with markup; however, currently, note contents are not used in calculating search results.
    			</p>

    			<h2>To Create a Note:</h2>
    			<ol>
    				<li>On the sidebar, click <span className="uitext">Add</span>.</li>
    				<li>In the <span className="uitext">Create Note</span> form, enter the note's title, tags, and content.</li>
    				<li>Click <span className="uitext">Submit</span>.</li>
    			</ol>
    		</main>
    	</section>
      <WebFooter />
    </body>
    )
  }
}


export default AddingNotesFAQ;
