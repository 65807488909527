import React from 'react';
import uuid from 'react-uuid'
import { Component } from 'react';
import { connect } from 'react-redux';
// import { addNote, putNote, delNote } from '../actions/noteActions'
import { withRouter } from 'react-router-dom';

import { Card, Row, Col, Form, Input, Button, List, Modal, Tooltip, PageHeader, Spin } from 'antd';

import { ExclamationCircleOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons';

import Tagger from '../temp_taginput'

import InfoTooltip from '../functional/InfoTooltip'

import {noteSuggestions} from '../../util/SearchUtils';


import {saveAddNote, savePutNote, saveDeleteNote} from '../../auth_comp/actions/authActions' //FIXME: REMOVE BEFORE FLIGHT

const { TextArea } = Input;

//https://ant.design/components/spin/#API
// Used to show loading list
const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

/**
 * Used to copy the props in the store that we need to reference
 * to also be in this class
 *
 * FIXME: Review this more
 */
const mapStateToProps = (state) => {
  return {
        notes: state.notes || state.note.notes,
        auth: state.auth, //FIXME: REMOVE BEFORE FLIGHT
        areNotesLoading: state.note.areNotesLoading
      }
}

/**
 * Used to map the functions we can dispatch to be callable from this
 * class.
 *
 * FIXME: Review this more?
 */
const mapDispatchToProps= (dispatch)=>{

    return{
        addNote: (note, history) => { dispatch(saveAddNote(note, history)) }, //{ dispatch(addNote(note)) }, //FIXME: DO BETTER, RENAME
        putNote: (note, history) => { dispatch(savePutNote(note, history)) },
        delNote: (uuid) => { dispatch(saveDeleteNote(uuid)) },//(uuid) => { dispatch(delNote(uuid)) },
        //saveNote: (note) => {dispatch(saveNote(note))}, //FIXME: REMOVE BEFORE FLIGHT
        //savePutNote: (note) => { dispatch(savePutNote(note))}
    }
}


/**
 * This component is used for the page that allows users to make new notes,
 * edit notes, and merge notes.
 */
class AddPanel extends Component {

//FIXME: REMOVE?
  state = {selectedNote: null}

  constructor(props) {
     super(props);
     this.updatePage(); //Get the page id if there is one
  }

  //Used to set the page to correspond to the URL. Modified from PagePanel
  updatePage = () => {

    //Get the ID of the page
    this.pageid = this.props.match.params.pageid;

    //If the current note is not null, and the page ID is null, then
    //we just switched from editing to something else, and therefore
    //need to clear the current note.
    if(this.currentNote != null && this.pageid == null) //We just switched
    {
      this.currentNote = null;
      this.formRef.current = null;
    }

    //If the page ID is null, then we are creating a new note
    if(this.pageid == null)
    {
      return;
    }

    //If the pageid is not null, we have to find which page we are editing
    for(let pg of this.props.notes)
     if(this.pageid === pg.uuid) //FIXME: USE THIS SORT OF THING MORE
     {
         this.currentNote = pg;
         break;
     }
     // console.log(this.currentNote);
  }

  //Reference to the main form so we can set its values.
  formRef = React.createRef();
  //Reference to the merge form so we can set its values.
  mergeRef = React.createRef();

  //Used when we have to add a note on click. FIXME: do better!
  handleClick = (note) => {
    this.props.addNote(note, this.props.history); //FIXME: CHANGE
  }

 /**
  * Used to display a reset warning to the users when the reset button is clicked.
  */
  resetWarning = () => {
    Modal.confirm({
      title: 'Confirm reset',
      content: 'If you reset now, the current note will not be saved.',
      icon: <ExclamationCircleOutlined />,
      onOk: () => {this.resetForm();},
      onCancel: () => {}
    });
  }

 /**
  * Used to display a warning when the users hits the "go back" button
  * from the merge option so that they know any changes will be lost.
  * This warning is only shown if changes have actually been made.
  */
  goBackWarning = () => {

    //Find the notes that we could be merging with.
    let possibleNotes = this.props.notes.filter(note => {
      return note.uuid === this.state.selectedNote.uuid; //FIXME: DO BETTER, STORE ORIG?
    })

    // console.log('pn', possibleNotes);
    // console.log('fd', this.mergeRef.current.getFieldsValue());
    // console.log(possibleNotes[0]);

    //Get the values of the form
    let noteObj = this.mergeRef.current.getFieldsValue();

    // console.log(noteObj);

    // console.log(possibleNotes[0] === noteObj);
    let sameTitle = possibleNotes[0].title === noteObj.title;
    let sameTags = possibleNotes[0].tags === noteObj.tags;
    let sameBody = possibleNotes[0].body === noteObj.contents; //FIXME: RENAME TO BODY

    // console.log(possibleNotes.length !== 1, !sameTitle, !sameTags, !sameBody);

    //If all values are the same, then nothing was changed, and we can go back without a warning.
    if(possibleNotes.length === 1 && sameTitle && sameTags && sameBody)
    {
      this.undoMerge();
    }
    else //There were changes made, so we have to warn the user about going back.
      Modal.confirm({
        title: 'Confirm revert',
        content: 'If you go back now, any changes to the existing note will be deleted.',
        icon: <ExclamationCircleOutlined />,
        onOk: () => {this.undoMerge(); },
        onCancel: () => {}
      });
  }

  //Used when submit is pressed to update or add the current note.
  onSubmit = values => {
    let tags = []; //FIXME: EDIT TAGS TO MAKE THIS NOT HAPPEN?

    for(let temp of values.tags) //FIXME: REMOVE
    {
      // console.log('temp',temp);
      tags.push(temp);
    }

    //Constuct the current note.
    let newNote = {
      title: values.title,
      tags: tags,
      body: values.contents,
      uuid: ((this.currentNote == null) ? uuid() : this.currentNote.uuid)
    }

    //if currentNote is null, we are making a new note, and just add it. Otherwise, we are updating an existing note
    //these calls will automaticlly change our page when it is ready.
    if(this.currentNote == null)
      this.props.addNote(newNote, this.props.history);
    else
      this.props.putNote(newNote, this.props.history);


    /*
    //FIXME: BETTER, SUPPORT MERGE ETC
    if(this.props.auth != null && this.props.auth.isAuthenticated) //FIXME: DO BETTER CHECKS
    {
      // newNote.ownerId = this.props.auth.user.id;
      // console.log(newNote);
      // this.props.saveNote(newNote); //FIXME: REMOVE BEFORE FLIGHT?
    }
    */
    // this.props.history.push(`/page/${newNote.uuid}`);
  };

 /**
  * Used when the merge button is pressed.
  */
  onMerge = values => {
    // console.log("hey?", values);
    let tags = []; //FIXME: EDIT TAGS TO MAKE THIS NOT HAPPEN?

    for(let temp of values.tags) //FIXME: REMOVE
    {
      // console.log('temp',temp);
      tags.push(temp);
    }

    //Construct the new note's values.
    let newNote = {
      title: values.title,
      tags: tags,
      body: values.contents,
      uuid: this.state.selectedNote.uuid
    }

    //if we had a current note, then we have to delete it. Otherwise, we were adding a note, so theres nothing to delete
    if(this.currentNote != null) //we were editing a note
      this.props.delNote(this.currentNote.uuid);

    //Update the note. This call will automaticlly direct us to the new note's page when it is ready
    this.props.putNote(newNote, this.props.history);

    // this.props.history.push(`/page/${newNote.uuid}`);
  };

  //FIXME: SHOW WARNING. https://ant.design/components/modal/
  resetForm = () => {
    this.formRef.current.resetFields();
  }

  //Called when a merge is undone.
  undoMerge = () => {
    this.setState({selectedNote: null});
  }

 /**
  * Used to get a tooltip to explain the list of similar notes.
  * FIXME: RENAME, DO BETTER
  */
  getTooltipTitle = () => {
    return (
    <div>
    Similar Notes <InfoTooltip text="Similar notes are listed below. Click on one of them to compare and merge it with your current note."/>
    </div>
    )
  }

 /**
  * Used to get a tooltip to explain that markdown can be used to style notes
  * FIXME: CHANGE LINK TO BE THIS PAGE? https://www.markdownguide.org/basic-syntax/
  * FIXME: RENAME? METHODIZE?
  */
  getContentsTitle = () =>{
    return (
      <div>
      Contents <InfoTooltip text="Markdown can be used to style your notes. Click here for reference."  onClick={() => {window.open("https://commonmark.org/help/")}}/>
      </div>
    )
  }

 /**
  * Used to get the title of the right panel
  * FIXME: DO BETTER, COMMENT BETTER ETC
  */
  getOtherTitle = () => {
      if(this.state.selectedNote == null)
        return this.getTooltipTitle();
      else
        return (
             "Existing Note"
        )

  }

  //From Page Panel
  getPageHeader = (name) => {
    return (
      <PageHeader className="note-header" onBack={() => {this.props.history.goBack()}} title={name} subTitle="" />
    )
  }

 //FIXME: DO BETTER, RENAME, METHODIZE/MAKE COMPONENTS?
 //FIXME: FIX HEADERS (EG SIMILAR NOTES) AND ALLOW REST TO SCROLL -> SHOULD BE FIXED
 //FIXME: ADD TOOLTIP WHEN DISABLED
 /**
  * Used to display the component on the right side of the screen. This is
  * either a list of similar notes, or the merge form .
  */
  getOtherView = () => {

    //FIXME: THIS DOESNT UPDATE
    //Maybe use these? <PageHeader title="Existing Note" onBack={() => {}} />
    if(this.state.selectedNote == null)
    {
        //FIXME: REFACTOR
        //Used to get the title and tags of teh current note or the form so that we can search for similar notes
        let tags = (this.formRef.current == null) ? ((this.currentNote == null) ? null : this.currentNote.tags) : this.formRef.current.getFieldsValue().tags;
        let title = (this.formRef.current == null) ? ((this.currentNote == null) ? null : this.currentNote.title)  : this.formRef.current.getFieldsValue().title;

        //Key to make it re-render when we switch from edit to add FIXME: DO BETTER

        //Return the list of similar notes.
        return (
          <>
          <List loading={this.props.areNotesLoading} key={this.currentNote} style={{'flex':'1 0 0', overflowY:'auto'}} dataSource={noteSuggestions(this.props.notes, title, tags, (this.currentNote != null) ? this.currentNote.uuid : null)} renderItem={item => (
            <List.Item key={item.uuid}>
              <Card hoverable size="small" bordered={false} style={{width:'100%', padding:'0px', margin:'0px'}} onClick={() =>{
                this.setState({selectedNote:item});
                //console.log('sn', item);
              }}>
              {item.title}
              </Card>
            </List.Item>
          )}>
          </List>
          </>
        )
    }
    else //We have to display the merge panel
    {

      //FIXME: ADD scrollToFirstError to MAIN?
      //if(this.formRef.current != null)
      //  console.log('fr', this.formRef.current.getFieldsValue());
      return (
        <>
        <Form scrollToFirstError layout="vertical" name="add-form" initialValues={{title:this.state.selectedNote.title, tags:this.state.selectedNote.tags, contents:this.state.selectedNote.body}} ref={this.mergeRef} onFinish={this.onMerge}>
          <Form.Item label="Title" name="title" rules={[{
              required: true,
              message: 'Please enter a title.',
              },]}>
              <Input />
          </Form.Item>


          <Tagger tags={this.state.selectedNote.tags} />

          <Form.Item label={this.getContentsTitle()} className="contentForm" style={{'flex':'0 1 auto', 'height':'100%'}}  name="contents" rules={[{
              required: true,
              message: 'Please enter the contents of your note.',
              },]}>
            <TextArea  className="addNoteContent" placeholder="" />
          </Form.Item>

           <Form.Item style={{marginBottom:'0px'}}>
           <Button type="primary" htmlType="submit">
            Merge
            </Button>

            <Button onClick={this.goBackWarning} danger>
              Go Back
            </Button>
          </Form.Item>

        </Form>
        </>
      )
    }
  }

  //Used to get the button to delete a note
  //FIXME: MAKE IT SO THIS DOES SOMETHING ON CLICK
  getDeleteButton = () => {
    return (
      <Tooltip title="delete">
        <Button shape="circle" type="link" icon={<DeleteOutlined />} />
      </Tooltip>
    )
  }


  render() {
  //  let otherView = this.getOtherView();
//FIXME: MAKE BOTH COLS SAME HEIGHT
//FIXME: LAYOUT, MAKE THINGS APART SEE SAMPLE
//FIXME: MAKE WARNINGS SHOW ON TAB SWITCH>
//FIXME: HIDE TAGGER
  //FIXME: DO A CHECK HERE, OR IS THERE NO REASON TO? IS THE ONLY REASON FOR AN UPDATE A PAGE REFRESH?
    this.updatePage(); //Make sure we match the page

    let titleTxt = (this.state.selectedNote == null) ? ((this.currentNote == null) ? "Create Note" : "Edit Note") : "Merge and Compare";

    //FIXME: DO BETTER/MORE WITH BACK BTN
    let currText = (this.currentNote == null) ? "Create a New Note" : this.getPageHeader("Current Note"); //"Current Note"; //FIXME: MAKE SURE DELETE NOTES?

    let initVals = (this.currentNote == null) ? null : {title:this.currentNote.title, tags:this.currentNote.tags, contents:this.currentNote.body};
    //initialValues={{title:this.state.selectedNote.title, tags:this.state.selectedNote.tags, contents:this.state.selectedNote.body}}
    // console.log('iv', this.currentNote);
    let extra = (this.currentNote == null) ? null : this.getDeleteButton();

    //Old card body style -> now part of LESS bodyStyle={{'flex': '1 0 0', 'overflow-y':'auto', height:'100%', display:'flex', flexDirection:'column'}}
    //Set row key to try to force re-construction when we switch from edit to add
    return (
      <>
        <h1 style={{textAlign:'center', paddingBottom:'8px'}}> {titleTxt} </h1>

        <Row key={this.currentNote} gutter={[16, 16]} style={{'flex':'1 1 auto', marginBottom:'0px'}} >
            <Col span={12} style={{'display':'flex'}}>
              <Card title={currText}  extra={extra} className="compare-cards" >
                <Form layout="vertical" initialValues={initVals} name="add-form" ref={this.formRef} onFinish={this.onSubmit} onValuesChange={()=>{
                  this.setState({}); //Used to update suggestions
                }}>
                  <Form.Item label="Title" name="title" rules={[{
                      required: true,
                      message: 'Please enter a title.',
                      },]}>
                      <Input readOnly={this.state.selectedNote != null} placeholder="Note Title"/>
                  </Form.Item>

                  {this.currentNote == null && <Tagger disabled={this.state.selectedNote != null} />}
                  {this.currentNote != null && <Tagger tags={this.currentNote.tags} disabled={this.state.selectedNote != null} />}

                  <Form.Item className="contentForm" style={{'flex':'0 1 auto', 'height':'100%'}} label={this.getContentsTitle()} name="contents" rules={[{
                      required: true,
                      message: 'Please enter the contents of your note.',
                      },]}>
                    <TextArea readOnly={this.state.selectedNote != null} className="addNoteContent" placeholder="Contents of note..." />
                  </Form.Item>

                   <Form.Item style={{marginBottom:'0px'}}>
                   <Button type="primary" htmlType="submit" disabled={this.state.selectedNote != null}>
                    Submit
                    </Button>

                    <Button onClick={this.resetWarning} disabled={this.state.selectedNote != null} danger>
                      Reset
                    </Button>
                  </Form.Item>

                </Form>
              </Card>
            </Col>
            <Col span={12} style={{'display':'flex'}}>
              <Card title={this.getOtherTitle()} className="compare-cards" bodyStyle={{height:'100%', display:'flex', flexDirection:'column'}}>
                {this.getOtherView()}
              </Card>
            </Col>
        </Row>
        </>
    )
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddPanel)); //FIXME: REVIEW!
