import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { setSearch } from '../actions/noteActions'
import { withRouter } from 'react-router-dom';

import { Card, Tag, Tooltip, Button } from 'antd';

import ReactMarkdown from 'react-markdown'

import { EditOutlined } from '@ant-design/icons';



/**
 * Used to copy the props in the store that we need to reference
 * to also be in this class
 *
 * FIXME: Review this more
 */
const mapStateToProps = (state) => {
  //console.log(state);
  return {
    notes: state.notes,
    lastSearch: state.lastSearch
  }
}

/**
 * Used to map the functions we can dispatch to be callable from this
 * class.
 */
const mapDispatchToProps = (dispatch) => {
  return{
    setSearch: (search) => { dispatch(setSearch(search)) } //FIXME: DO BETTER, RENAME
  }
}


/**
 * Used to preview a note. Such as in the search and list panels.
 */
class NoteComponent extends Component {

//https://stackoverflow.com/questions/44575727/react-js-toggle-adding-a-class-on-hover#44577103
  constructor() {
    super();
    this.state = {
      isHovered: false
    };

    this.handleHover = this.handleHover.bind(this);
  }

  handleHover(){
    this.setState(prevState => ({
        isHovered: !prevState.isHovered
    }));
  }

  //From Page panel, used to toggle edit on hover--hopefully
  getEditButton = () => {
    const isHovered = this.state.isHovered;
    return (
      <>
      {isHovered &&
      <Tooltip title="edit">
        <Button shape="circle" type="link" icon={<EditOutlined />} onClick={(e) =>{
          this.props.history.push(`/edit/${this.props.note.uuid}/`);
          e.stopPropagation();
        }} />
      </Tooltip>
    }
    </>
    )
  }

  //Used to create a ref so that we can set the value of our search bar.
  formRef = React.createRef();

 /**
  * Function called on click. Used to bring the user to the page for the
  * given note.
  * @param  {[type]} note The note that was clicked
  */
  noteClick = (note) => {
    console.log(note);
    this.props.history.push(`/page/${note.uuid}`);
  }

/**
 * This was used to set the search text to be a given text?
 * FIXME: REVIEW AND CHECK IF USED
 * @param  {[type]} searchTxt The new search text
 */
  onSearch = (searchTxt) => {

    this.props.setSearch(searchTxt);
    this.props = {
      ...this.props,
      lastSearch: searchTxt
    }
    this.formRef.current.setFieldsValue({searchbar : searchTxt});
  }

  //FIXME: MAKE TAG SEARCH WORK
  render() {
    let tagsList = this.props.note.tags.map(tag=>{
      return (
        <Tag key={tag} onClick={(e) => {
          //this.onSearch(tag); //FIXME: ENABLE
          //e.stopPropagation();
          //this.forceUpdate(); //Re-render
          this.props.onTagClick(e, tag);
        }}>
        {tag}
        </Tag>
      )
    })

    //FIXME: DEAL WITH MULTI LINE, B/C MD LOCK HEIGHT BETTER? (TWO ISSUES HERE)
    let displayText = (this.props.note.body.length > 100) ? this.props.note.body.slice(0, 100) + '...' : this.props.note.body;
    //FIXME:DO BETTER
    return (
      <Card onMouseEnter={this.handleHover} onMouseLeave={this.handleHover} key={this.props.note.uuid} className="full-note-card" title={this.props.note.title} extra={this.getEditButton()} onClick={() => {
        //this.noteClick(note)  //FIXME: ENABLE
        this.props.noteClicked(this.props.note);
        } }>
      <div style={{marginBottom:'16px'}}>{tagsList}</div>
      <ReactMarkdown>{displayText}</ReactMarkdown>
      </Card>
    )

  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NoteComponent)); //FIXME: REVIEW!
