import '../web.css';
import React from 'react';

import { HashLink as Link } from 'react-router-hash-link';

import { Helmet } from "react-helmet";

import { useSelector } from "react-redux";

import WebNav from './WebNav'
import WebFooter from './WebFooter'

function HomePage() {

	const auth = useSelector(state => state.auth);

	return (
		<body className="webcontainer">
			<Helmet>
				<title>XING</title>
			</Helmet>
			<WebNav url="/" />
			<header className="hero">
				<nav className="herotext">
					<h1>XING</h1>
					<p>
						<Link to="/faq/searching#">Search</Link>
						{' '}|{' '}
						<Link to="/faq/editing#">Edit</Link>
						{' '}|{' '}
						<Link to="/faq/merging#">Merge</Link>
					</p>
					<h2>Your Notes</h2>
				</nav>
				{' '}
				<nav className="loginhero">
					{ !auth.isAuthenticated &&
						<Link to="/register#">Register</Link>
					}
					<Link to="/login#">{auth.isAuthenticated ? 'Open XING' : 'Login'}</Link>
				</nav>
				<img src="./web/xing-note-1.png" alt="Sample of XING UI." />
			</header>

			<section className="centeredcontent capcontentwidth reasons-grid">
				<h2>Why use XING?</h2>

				<article>
					<h3>Quick & Convenient</h3>
					<p>XING is designed for you to quickly and conveniently write notes.</p>
				</article>

				<article>
					<h3>Intelligent Search</h3>
					<p>
						With XING, you can search for your notes based off of their title and tags: in effect, creating a search
						engine of your thoughts.
					</p>
				</article>

				<article>
					<h3>Organization</h3>
					<p>
						While creating and viewing notes, XING offers similar notes that you can view. If two notes are about the
						same topic, you can merge them together.
					</p>
				</article>

				<article>
					<h3>Responsive</h3>
					<p>
						XING adapts to any device and any screen-size so that you can access your notes on any platform—from
						your phone to a desktop.
					</p>
				</article>

				<article>
					<h3>Style with Markdown</h3>
					<p>
						In XING, you can style your notes with markdown to include: code blocks, block quotes, lists, text formatting, and more.
					</p>
				</article>
			</section>

			<hr />

			<section className="centeredcontent capcontentwidth updates">
				<h2>Updates</h2>
				<nav className="update-preview-grid">
					<Link to="/updates#improvedSiteDesign04252021" className="update-preview-card">Website Design Improvements <br /> 4/25/2021</Link>
					<Link to="/updates#newWebsite04062021" className="update-preview-card">New Website Started <br /> 4/6/2021</Link>
					<div className="faq-card">More information soon...</div>
				</nav>
			</section>
			<WebFooter />
		</body>
	)
}


export default HomePage;
