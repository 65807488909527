import '../web.css';
import React from 'react';
import { Component } from 'react';

import {Helmet} from "react-helmet";

import WebNav from './WebNav'
import WebFooter from './WebFooter'

class UpdatesPage extends Component {

  render()
  {
    return (
      <body className="webcontainer">
        <Helmet>
          <title>XING | Updates</title>
        </Helmet>
      <WebNav url="/updates" />
      <section className="centeredcontent capcontentwidth">
    <h1>XING | Updates</h1>

    <div className="updates-grid">
    <article className="update-card" id="improvedSiteDesign04252021">
      <h2>Improved Website Design</h2>
      <p className="meta">4/25/2021</p>
      <hr />
      <div className="update-card-content">
        <p>
          In this update, I improved the design of this website by:
        </p>
        <ul>
          <li>Improving the CSS styles for all pages.</li>
          <li>Refactoring the website to use structured authoring.</li>
        </ul>
        <p>
          Previously, pages (such as the FAQ, Updates and About Project page) were minimally styled.
          With this update, I styled all of these pages with higher quality designs that are optimized for larger
          screens (such as on a laptop or desktop).
          <br /><br />
          In addition, I refactored all of the website's pages to use structured authoring with HTML tags
          that convey meaning (instead of primarily using divs).
        </p>
      </div>
    </article>
    <article className="update-card" id="newWebsite04062021">
      <h2>New Website in Development</h2>
      <p className="meta">4/6/2021</p>
      <hr />
      <div className="update-card-content">
        <p>
          On 4/6/2021, I started work on a new website for XING (this one)!
          More information will be added soon.
        </p>
      </div>
    </article>
    </div>
</section>

      <WebFooter />
    </body>
    )
  }
}


export default UpdatesPage;
