//https://blog.bitsrc.io/build-a-login-auth-app-with-mern-stack-part-2-frontend-6eac4e38ee82
import axios from "axios";
import { io } from "socket.io-client"
import { getUserNotes } from "../actions/authActions";
import store from "../store";

var socket;

const setAuthToken = token => {
  if (token) {
    // Apply authorization token to every request if logged in
    axios.defaults.headers.common["Authorization"] = token;

    if(socket)
      socket.disconnect();

    socket = io.connect('https://xing.api.conntinuity.org', {
      extraHeaders: {
        Authorization: token
      }
    });

    socket.on('connect', () => {
      socket.emit("/api/connect", {product : 'xing', room : 'xing-pub-sub'})
      console.log("connected")
    })

    socket.on('disconnect', () => {
      console.log("Disconnect")
    })

    socket.on('message', (message) => {console.log(message); store.dispatch(getUserNotes()); })

  } else {
    // Delete auth header
    delete axios.defaults.headers.common["Authorization"];

    if(socket)
      socket.disconnect();
  }
};

export default setAuthToken;
