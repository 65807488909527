import '../../web.css';
import React from 'react';
import { Component } from 'react';

import {Helmet} from "react-helmet";

import { HashLink as Link } from 'react-router-hash-link';

import WebNav from '../WebNav'
import WebFooter from '../WebFooter'

class MergingFAQ extends Component {

  render()
  {
    return (
      <body className="webcontainer">
        <Helmet>
          <title>XING | FAQ - Merging Notes</title>
        </Helmet>
      <WebNav url="/faq/merging" />

      <section className="faqsection">
    		<aside>
    			<nav>
    				<h3>XING | FAQ</h3>
    				<ul>
    					<li><Link to="/faq/addingnotes#">Creating Notes</Link></li>
    					<li><Link to="/faq/searching#">Searching Notes</Link></li>
    					<li><Link to="/faq/editing#">Editing Notes</Link></li>
    					<li><Link className="active" to="/faq/merging#">Merging Notes</Link></li>
    				</ul>
    			</nav>
    		</aside>
    		<main className="faqcontent">
    			<h1>XING | FAQ - Merging Notes</h1>
    			<p>
    				While using XING, if you discover that two notes are about the same topic,
    				you can merge them together to stay organized.
    			</p>
    			<h2>To Merge Two Notes:</h2>
    			<ol>
    				<li><Link to="/faq/editing#">Open the first note in the edit menu.</Link></li>
    				<li>In the <span className="uitext">Similar Notes</span> panel, click the note to merge with.</li>
    				<li>Copy the contents of the first note into the second note.</li>
    				<li>Click <span className="uitext">Merge</span>.</li>
    			</ol>
    		</main>
    	</section>
      <WebFooter />
    </body>
    )
  }
}


export default MergingFAQ;
