import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { sendRecoverPassword } from "./actions/authActions";
// import classnames from "classnames"; //FIXME: WHAT? -> REMOVE

import {Helmet} from "react-helmet";

import { Card, PageHeader, Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const DASHBOARD_PATH = "/search";

//https://ant.design/components/form/
const validateMessages = {
  required: 'Your email address is required!',
  types: {
    email: 'Please enter a valid email!',
  }
};


class PasswordReset extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {}
    };
  }

  componentDidMount() {
   // If logged in and user navigates to Register page, should redirect them to dashboard
   if (this.props.auth.isAuthenticated) {
     this.props.history.push(DASHBOARD_PATH);
   }
 }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push(DASHBOARD_PATH); // push user to dashboard when they login
    }if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    //console.log(e);
    //e.preventDefault(); //FIXME: WHy ant doesnt need this?
    const userData = {
      email: this.state.email.trim()
    };
    this.props.sendRecoverPassword(userData, this.props.history); // since we handle the redirect within our component, we don't need to pass in this.props.history as a parameter
  };

//FIXME: VERIFY CSS CLASS
  getPageHeader = (name) => {
    return (
      <PageHeader className="note-header" onBack={() => {this.props.history.goBack()}} title={name} />
    )
  }

  getSubtitle = () => {
    return (
      <div style={{color:'black'}}>
        Don't have an account? <Link to="/register">Register</Link>
    </div>
    )
  }

  getEmailErrors = () => {
    return this.state.errors.email || this.state.errors.emailnotfound;
  }

  getEmailStatus = () => {
    return (this.getEmailErrors()) ? 'error' : 'success';
  }

  render() {
    // const { errors } = this.state;
    //FIXME: CHECK CSS, MOST OF IT IS UNUSED
    //FIXME: USE rules={[{ required: true, type: 'email' }]} IN FORM?
    return (
      <>
        <Helmet>
          <title>XING | Reset Password</title>
        </Helmet>
      <Card title={this.getPageHeader("Reset Password")} className="login-form">
          <Form layout="vertical" validateMessages={validateMessages} onFinish={(e) => {this.onSubmit(e)}} >

            {
              /* <Form.Item name="email" rules={[{ required: true, type: 'email' }]} validateStatus={this.getEmailStatus()} help={this.getEmailErrors()} >*/
            }
            <Form.Item name="email" rules={[{ required: true, type: 'email' }]} >
              <Input onChange={this.onChange} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button">
                Send Recovery Email
              </Button>
            </Form.Item>
          </Form>
        </Card>
        </>
    );
  }
}

//FIXME: WHAT?
PasswordReset.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { sendRecoverPassword }
)(PasswordReset);
