import React from 'react';
import { Component } from 'react';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom'

// import Navigator from './components/Navigator' //Unused
import ListPanel from './components/Panels/ListPanel' //List of everything in our datastore
import SearchPanel from './components/Panels/SearchPanel'
import AddPanel from './components/Panels/AddPanel'
import AboutPanel from './components/Panels/AboutPanel'
import PagePanel from './components/Panels/PagePanel'

import TOSPanel from './components/Panels/TOSPanel'


import Login from './auth_comp/login'
import Register from './auth_comp/register'
import PrivateRoute from "./auth_comp/PrivateRoute";
import Dashboard from "./auth_comp/dashboard";

import PasswordReset from "./auth_comp/passwordreset";
import ChangePassword from "./auth_comp/changepassword"; //FIXME: HIDE BETTER

import { Provider } from "react-redux";
import store from "./auth_comp/store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./auth_comp/utils/setAuthToken";
import { setCurrentUser, logoutUser, getUserNotes } from "./auth_comp/actions/authActions";


//Public website
import HomePage from "./website/HomePage"
import AboutPage from "./website/AboutPage"
import FeaturesPage from "./website/FeaturesPage"
import FAQPage from "./website/FAQPage"

import AddingNotesFAQ from "./website/faqs/AddingNotesFAQ"
import SearchingFAQ from "./website/faqs/SearchingFAQ"
import EditingFAQ from "./website/faqs/EditingFAQ"
import MergingFAQ from "./website/faqs/MergingFAQ"

import UpdatesPage from "./website/UpdatesPage"

/*
 * More TODOs:
 * Add Back button to page panel
 * Add similar notes to page panel
 * Add Edit to page panel
 * Make page panel show up diff in sidebar
 */
//FIXME: DO 404 PAGE
//FIXME: MAKE THINGS US UUID MORE
import './App.less';

import AntNav from './components/AntNav'

import { Layout } from 'antd';
const { Content, Footer } = Layout;

//AUTH
// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime)
  {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "./login";
  }
  else
  {
      store.dispatch(getUserNotes());
  }
}

// document.addEventListener('visibilitychange', () => {
//   if (document.visibilityState === 'visible') {
//     console.log('hey now... hey now...')
//     store.dispatch(getUserNotes());
//   }
// });
//END AUTH

class App extends Component {
  //TODO: MESS WITH CSS
  //FIXME: REMOVE PROVIDER BEFORE FLIGHT
  //FIXME: UPDATE SO THAT THIS DOESNT DIRECT TO SETTINGS AS 404
  render(){
    return (
      <Provider store={store} >
        <BrowserRouter>
          <Switch>

            <Route path={['/search', '/add', '/edit', '/edit/:pageid', '/list', '/settings', '/page/:pageid', '/dashboard']}>
              <div className="App">
                <Layout style={{ minHeight: '100vh' }}>
                  <AntNav />
                  <Layout className="site-layout">
                    <Content style={{ padding: '16px', minHeight: '250px', overflow:'hidden', height:'calc(100vh - 70px)', position:'relative', display:'flex', flexDirection:'column'}}>
                      <PrivateRoute exact path="/search" component={SearchPanel} />
                      <PrivateRoute exact path="/add" component={AddPanel} />
                      <PrivateRoute exact path="/edit/:pageid" component={AddPanel} />
                      <PrivateRoute exact path="/list" component={ListPanel} />
                      <PrivateRoute exact path="/settings" component={AboutPanel} />
                      <PrivateRoute path="/page/:pageid" component={PagePanel} />

                      <PrivateRoute exact path="/dashboard" component={Dashboard} />
                    </Content>
                    <Footer style={{ textAlign: 'center',  'zIndex':'0',  width:'100%', bottom:'0px'}}>XING</Footer>
                  </Layout>
                </Layout>
              </div>
            </Route>

            <Route exact path="/login" component={Login} />
            <Route exact path="/passwordreset/:token" component={ChangePassword} />
            <Route exact path="/passwordreset" component={PasswordReset} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/tos" component={TOSPanel} />



            <Route exact path="/" component={HomePage} />
            <Route exact path="/about" component={AboutPage} />
            <Route exact path="/features" component={FeaturesPage} />
            <Route exact path="/faq" component={FAQPage} />
            <Route exact path="/faq/addingnotes" component={AddingNotesFAQ} />
            <Redirect from="/faq/creating" to="/faq/addingnotes" />
            <Redirect from="/faq/adding" to="/faq/addingnotes" />
            <Route exact path="/faq/searching" component={SearchingFAQ} />
            <Route exact path="/faq/editing" component={EditingFAQ} />
            <Route exact path="/faq/merging" component={MergingFAQ} />
            <Route exact path="/updates" component={UpdatesPage} />

            <Route component={AboutPanel} />
        </Switch>
      </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
