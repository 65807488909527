import React from 'react';
import { Component } from 'react';
// import { connect } from 'react-redux';

import {Helmet} from "react-helmet";

import { Card } from 'antd';



class AboutPanel extends Component {


  render()
  {
    return (
      <>
      <Helmet>
        <title>XING | 404</title>
      </Helmet>
      <Card title="Under Development—Please ignore. ">
      <p>Sample text</p>
      </Card>
      </>
    )
  }
}


export default AboutPanel;
