import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { setSearch } from '../actions/noteActions'
import { withRouter } from 'react-router-dom';

import { Input, Form, Affix, Skeleton, Card, Empty } from 'antd';


import NoteComponent from '../functional/NoteComponent'

import {searchFilter} from '../../util/SearchUtils';

const { Search } = Input;


/**
 * Used to copy the props in the store that we need to reference
 * to also be in this class
 *
 * FIXME: Review this more
 */
const mapStateToProps = (state) => {
   //console.log('search state', state);
  return {
        notes: state.notes || state.note.notes,
        lastSearch: state.lastSearch || state.note.lastSearch,
        areNotesLoading: state.note.areNotesLoading
      }
}

/**
 * Used to map the functions we can dispatch to be callable from this
 * class.
 */
const mapDispatchToProps = (dispatch) => {
    return{
        setSearch: (search) => { dispatch(setSearch(search)) } //FIXME: DO BETTER, RENAME
    }
}

//Affix Reference: https://gary-shen.github.io/ant-design/components/affix/

/**
 * Used to display the page used to search all notes
 */
class SearchPanel extends Component {

  //FIXME: ADD TAG SEARCHING
  //state = {searchStr: ""}
  formRef = React.createRef(); //Used to create a ref so that we can set the value of our search bar.

  tRef = React.createRef(); //FIXME: REM?

  //Used to open note whn it is clicked.
  noteClick = (note) => {
    // console.log(note);
    this.props.history.push(`/page/${note.uuid}`);
  }

  //FIXME: REVIEW
  //Used to set the search text
  onSearch = (searchTxt) => {

    this.props.setSearch(searchTxt);
    this.props = {
      ...this.props,
      lastSearch: searchTxt
    }
    this.formRef.current.setFieldsValue({searchbar : searchTxt});
  }

  onChange = (searchTxt) => {

    // console.log(searchTxt);
    // console.log(searchTxt.target.value);
    this.onSearch(searchTxt.target.value);
  }




  render() {

    //Get the last thing searched for
    let searchStr = this.props.lastSearch;

    //Call the searchFilter functiion to get a list of notes (in order) to show
    let notesList = (this.props.areNotesLoading) ? <Card className="full-note-card"><Skeleton active /></Card> : searchFilter(this.props.notes, searchStr).map(note=>{
     return (
       <NoteComponent key={note.uuid} note={note} onTagClick={(e, tag) => {
         this.onSearch(tag);
         e.stopPropagation();
         this.forceUpdate(); //Re-render
       }}
       noteClicked={(note) => {
         this.noteClick(note);
       }} />
     )
    })
 //PLAN: MAKE ON CHANGE?

    return (
      <div ref={(node) => { this.container = node; }} style={{overflowY:'auto',  marginLeft:'-16px', marginRight:'-16px'}}>

        <Affix target={() => this.container}>
          <div className="affixHeader">
            <Form ref={this.formRef}  initialValues={{'searchbar':searchStr}}>

              <Form.Item name="searchbar">
                <Search className="note-search-bar" placeholder="Search" allowClear onChange={this.onChange} onSearch={this.onSearch} />
              </Form.Item>
             </Form>
          </div>
        </Affix>
        <div>
          {notesList.length === 0 && <Empty description="No results found." />}
          {notesList}
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchPanel)); //FIXME: REVIEW!
