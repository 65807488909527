//https://blog.bitsrc.io/build-a-login-auth-app-with-mern-stack-part-2-frontend-6eac4e38ee82

import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import noteReducer from "../../components/reducers/noteReducer"

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  note: noteReducer,
});
