//https://blog.bitsrc.io/build-a-login-auth-app-with-mern-stack-part-2-frontend-6eac4e38ee82

import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "./actions/authActions";
// import classnames from "classnames"; //FIXME: WHAT? -> REMOVE

import {Helmet} from "react-helmet";

import { Card, PageHeader, Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const DASHBOARD_PATH = "/search";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {}
    };
  }

  componentDidMount() {
   // If logged in and user navigates to Register page, should redirect them to dashboard
   if (this.props.auth.isAuthenticated) {
     this.props.history.push(DASHBOARD_PATH);
   }
 }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push(DASHBOARD_PATH); // push user to dashboard when they login
    }if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    //console.log(e);
    //e.preventDefault(); //FIXME: WHy ant doesnt need this?
    const userData = {
      email: this.state.email.trim(),
      password: this.state.password
    };
    this.props.loginUser(userData); // since we handle the redirect within our component, we don't need to pass in this.props.history as a parameter
  };

//FIXME: VERIFY CSS CLASS
  getPageHeader = (name) => {
    return (
      <PageHeader className="note-header" onBack={() => {this.props.history.goBack()}} title={name} subTitle={this.getSubtitle()} />
    )
  }

  getSubtitle = () => {
    return (
      <div style={{color:'black'}}>
        Don't have an account? <Link to="/register">Register</Link>
    </div>
    )
  }

  getEmailErrors = () => {
    return this.state.errors.email || this.state.errors.emailnotfound;
  }

  getEmailStatus = () => {
    return (this.getEmailErrors()) ? 'error' : 'success';
  }

  getPasswordErrors = () => {
    return this.state.errors.password || this.state.errors.passwordincorrect;
  }

  getPasswordStatus = () => {
    return (this.getPasswordErrors()) ? 'error' : 'success';
  }

  render() {
    // const { errors } = this.state;
    //FIXME: CHECK CSS, MOST OF IT IS UNUSED
    return (
      <>
      <Helmet>
        <title>XING | Login</title>
      </Helmet>
      <Card title={this.getPageHeader("Login")} className="login-form">
          <Form layout="vertical" onFinish={(e) => {this.onSubmit(e)}}>

            <Form.Item name="email" validateStatus={this.getEmailStatus()} help={this.getEmailErrors()}>
              <Input onChange={this.onChange} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
            </Form.Item>

            <Form.Item name="password" validateStatus={this.getPasswordStatus()} help={this.getPasswordErrors()}>
              <Input.Password type="password" onChange={this.onChange} prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button">
                Log in
              </Button>
               <Link to="/passwordreset">Forgot Password?</Link>
            </Form.Item>
          </Form>
        </Card>
        </>
    );
  }
}

//FIXME: WHAT?
Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser }
)(Login);
