import React from 'react';
import { Component } from 'react';
import ReactMarkdown from 'react-markdown'

import { Card } from 'antd';

const TOS_TEXT = "This version of the application is in development. For the stable branch, please see [https://xing.conntinuity.org/](https://xing.conntinuity.org/).\n\n" +
                 "While this application is visible on the internet, its purpose is to serve as a mechanism for me to test and use my application--this includes simulating what a production branch would look like (hence why this is running on a server). " +
                 "Because this is the development branch of the application, it may be unstable, have bugs, have exploits, and changes can (and will) be made without any notice (**this includes the terms of service!**).\n\n" +
                 "If you decide to use this application, you accept the risks including (but not limited to) the following, and will not hold me accountable: \n" +
                 "* Data Loss\n* Instability and Unpredictable Downtime\n* Permanant and Imediant Suspention of Use without warning\n* The Possibility of Exploits\n* Data Collection needed for program functionality or incident to program development\n\n" +
                 "By using this program, you agree to not attempt any explots unless you give me prior notice, and agree to inform me of any found so that they can be fixed. You also agree to not attempt to copy or redistribute this application in any means except for sharing this website.\n\n" +
                 "Unless expressly granted permission by me, use of this program is unintended, and therefore can result in account suspension. For any questions, concerns or issues, please contact me.";

class TOSPanel extends Component {



  render()
  {
    return (
      <Card title="XING Development Branch - Terms of Service ">
      <p>Congratulations, you've found an application that is in development! With my mandatory sarcasm out of the way, here are the current terms of this application.</p>
      <br />
      <ReactMarkdown>{TOS_TEXT}</ReactMarkdown>
      </Card>
    )
  }
}


export default TOSPanel;
