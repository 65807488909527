const similarity = require('string-similarity');

const TAG_WEIGHT = 1;
const TITLE_WEIGHT = 0.5;
const THRESHOLD = 0.1;

/**
 * Used to filter the list of notes given based off of a search
 * @param  {[type]} notes     The list of notes
 * @param  {[type]} searchStr The search string
 * @return {[type]}           A list of notes (in order) that match the search
 */
export const searchFilter = (notes, searchStr) => {


  if(searchStr==="")//Empty Search
    return notes;

  //FIXME: DO BETTER SEARCH
  let tokens = searchStr.toLowerCase().trim().split(" ");

  return notes.map(note => {

    let cnt = 0;

    //Do the smaller comparison looking for notes that share tags with our search
    if(tokens.length < note.tags.length)
    {
        for(let temp of tokens)
          for(let tag of note.tags)
            if(tag.toLowerCase() === temp)
          //if(note.tags.indexOf(temp) !== -1)
              cnt += TAG_WEIGHT;
    }
    else
    {
      for(let temp of note.tags)
        if(tokens.indexOf(temp.toLowerCase()) !== -1)
          cnt += TAG_WEIGHT;
    }


    //FIXME: DO BETTER
    // let titleTokensCmp = note.title.toLowerCase().split(" ");
    cnt += (TITLE_WEIGHT * similarity.compareTwoStrings(searchStr, note.title));
     // console.log(searchStr, note.title, cnt);
    /*
    if(tokens.length < titleTokensCmp.length)
    {
      for(let temp of tokens)
        if(titleTokensCmp.indexOf(temp) !== -1)
        {
          cnt += 0.5
        }
    }
    else
    {
      for(let temp of titleTokensCmp)
        if(tokens.indexOf(temp) !== -1)
          cnt += 0.5
    }
    */

    // console.log("HELLO?");
    // console.log(note.title, cnt);
    note.cnt = cnt;
    return note;
  }).filter(note => {
    return note.cnt >= THRESHOLD//!== 0
  }).sort((a, b) => {
    return  - a.cnt + b.cnt; //FIXME: VERIFY ORDER
  }).filter(note => {
    delete note.cnt;
    return note;
  })



}

//FIXME: BETTER NAME
/**
 * Gets a list of notes based off of a search
 * @param  {[type]} notes The list of notes (object array)
 * @param  {[type]} title The title of the current note (string)
 * @param  {[type]} tags  The array of tags of the current note
 * @param  {[type]} uuid  Optional: The uuid of the current note, so that it can be excluded from the list
 * @return {[type]}       A list of possible similar notes
 */
export const noteSuggestions = (notes, title, tags, uuid) => {

   //console.log('nst: ', tags);
   // console.log('n', notes, title, tags, uuid)
  if(title == null && tags == null)
    return notes;

    let titleTokensCurr = (title != null) ? title.toLowerCase().trim().split(" ") : [];
    // console.log(titleTokensCurr);
  // if(tags == null || tags.length === 0) //FIXME: IMPLEMENT TITLE< DO BETTER
    // return notes;

    return notes.map(note => {

      let cnt = 0;

      if(tags != null)
      {
        if(tags.length < note.tags.length)
        {
            for(let temp of tags)
              for(let tag of note.tags)
                if(tag.toLowerCase() === temp.toLowerCase())// if(note.tags.indexOf(temp) !== -1)
                  cnt++;
        }
        else
        {
          for(let temp of note.tags)
            for(let tag of tags)
              if(tag.toLowerCase() === temp.toLowerCase())//if(tags.indexOf(temp.toLowerCase()) !== -1)
              cnt++;
        }
      }

      //FIXME: DO BETTER
      let titleTokensCmp = note.title.toLowerCase().split(" ");

      if(titleTokensCurr.length < titleTokensCmp.length)
      {
        for(let temp of titleTokensCurr)
          if(titleTokensCmp.indexOf(temp) !== -1)
          {
            // console.log(temp, 'is in');
            cnt += 0.5
          }
      }
      else
      {
        for(let temp of titleTokensCmp)
          if(titleTokensCurr.indexOf(temp) !== -1)
            cnt += 0.5
      }

      // console.log("HELLO?");
      // console.log(note.title, cnt);
      note.cnt = cnt;
      return note;
    }).filter(note => {
      return note.cnt !== 0 && (uuid == null || note.uuid !== uuid);
    }).sort((a, b) => {
      return  - a.cnt + b.cnt; //FIXME: VERIFY ORDER
    }).filter(note => {
      delete note.cnt;
      return note;
    }) //FIXME: HEAP?
    /*
  return notes.filter(note=>{
      if(title == null && tags == null)
        return true;

      //FIXME: IMPLEMENT TITLE
      if(tags == null)
        return true;


    // if(searchStr==="") //Empty search
      return true;
    // return note.title.indexOf(searchStr) > -1; //FIXME: DO BETTER
  })
  */

}
