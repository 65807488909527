import '../web.css';
import React from 'react';
import { Component } from 'react';

import {Helmet} from "react-helmet";

import WebNav from './WebNav'
import WebFooter from './WebFooter'

class AboutPage extends Component {

  render()
  {
    return (
      <body className="webcontainer">
        <Helmet>
            <title>XING | About</title>
        </Helmet>
      <WebNav url="/about" />
      <header className="abouthero">
  <h1>XING | About</h1>
  <p>
    XING is designed for you to be able to quickly write down short notes—similar
    to how you might use sticky notes. However, by using XING, you can ensure that
    your notes remain organized and easy to find.
  </p>
</header>

<section className="centeredcontent capcontentwidth">
  <main>
    <article>
      <h2>Background</h2>
      <p>
        With the amount of information accessible on the internet, it is common to search for
        solutions to problems and questions that we have. However,
        it can sometimes take a while to articulate your thoughts into a search
        that yields results. Finally, when you find a helpful resource, your options are to:
      </p>

        <ul>
          <li>Bookmark the page, and look through your list of bookmarks to try to find it.</li>
          <li>Write the information down on paper where it can be equally hard to find again.</li>
        </ul>

      <p>
        XING sets out to provide an alternative option to this. In XING, whenever you have something
        that you would like to remember, you can record the information in a note along with
        any search terms that you think could be helpful locating it again. Then, when you want to
        remember something, you can search for it in XING and view your note.
      </p>
    </article>
  </main>
</section>

<hr />
<section className="centeredcontent capcontentwidth opensrc">
    <h2>Open Source</h2>
    <p>XING was built using a number of open source libraries including: </p>
    <nav className="opensrc-grid">
      <a href="https://ant.design/" target="_blank" rel="noopener noreferrer" className="opensrc-card">Ant Design</a>
      <a href="https://github.com/kelektiv/node.bcrypt.js#readme" target="_blank" rel="noopener noreferrer" className="opensrc-card">Bcrypt</a>
      <a href="https://github.com/expressjs/express" target="_blank" rel="noopener noreferrer" className="opensrc-card">Express</a>
      <a href="https://fontawesome.com/v4.7.0/license/" target="_blank" rel="noopener noreferrer" className="opensrc-card">Font Awesome (website icons only)</a>
      <a href="https://nodejs.org/en/" target="_blank" rel="noopener noreferrer" className="opensrc-card">Node.JS</a>
      <a href="https://github.com/jaredhanson/passport" target="_blank" rel="noopener noreferrer" className="opensrc-card">Passport</a>
      <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer" className="opensrc-card">React</a>
      <a href="https://redux.js.org/" target="_blank" rel="noopener noreferrer" className="opensrc-card">Redux</a>
    </nav>
</section>

      <WebFooter />
    </body>
    )
  }
}


export default AboutPage;
