import React from 'react';
import { Component } from 'react';
import { Tooltip } from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';

/**
 * This component is used to display an information icon, and then provide
 * information in a tooltip when it is hovered over.
 *
 * FIXME: Make a better name?
 */
class InfoTooltip extends Component {

  render() {
    //Return the tooltip with the given text on a QuestionCircleOutlined icon.
    return (
      <Tooltip title={this.props.text} onClick={(e) => {
        if(this.props.onClick != null)
        this.props.onClick(e)
      }}>
        <QuestionCircleOutlined />
      </Tooltip>
    )
  }
}


export default InfoTooltip;
