//https://blog.bitsrc.io/build-a-login-auth-app-with-mern-stack-part-2-frontend-6eac4e38ee82
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "./actions/authActions";

import {Helmet} from "react-helmet";

//import classnames from "classnames"; //FIXME: REM / UNINSTALL?

import {Card, PageHeader, Form, Input, Checkbox, Button} from 'antd';

const DASHBOARD_PATH = "/search"; //FIXME: DO BETTER, GLOBAL VAR? OR INVERSE PROTECTED ROUTE?

class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      password: "",
      password2: "",
      errors: {}
    };
  }

  componentDidMount() {
  // If logged in and user navigates to Login page, should redirect them to dashboard
  if (this.props.auth.isAuthenticated) {
    this.props.history.push(DASHBOARD_PATH);
  }
}
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  //FIXME: MAKE USERNAMES DISTINCT
  onSubmit = e => {
    //e.preventDefault(); //FIXME: WHY BROKEN ON ANT?
    const newUser = {
      name: this.state.name,
      email: this.state.email.trim(),
      password: this.state.password,
      password2: this.state.password2,
      code: this.state.code
    };
    this.props.registerUser(newUser, this.props.history);
  };

//FIXME: VERIFY CLASS
  getPageHeader = (name) => {
    return (
      <PageHeader className="note-header" onBack={() => {this.props.history.goBack()}} title={name} subTitle={this.getSubtitle()} />
    )
  }

  getSubtitle = () => {
    return (
      <div style={{color:'black'}}>
        Already have an account? <Link to="/login">Log in</Link>
      </div>
    )
  }

  getEmailErrors = () => {
    return this.state.errors.email;
  }

  getEmailStatus = () => {
    return (this.getEmailErrors()) ? 'error' : 'success';
  }

  getPasswordErrors = () => {
    return this.state.errors.password;
  }

  getPasswordStatus = () => {
    return (this.getPasswordErrors()) ? 'error' : 'success';
  }

  //FIXME: DO BETTER AGREEMENT
  //FIXME: AGREEMENT FROM https://ant.design/components/form/#components-form-demo-normal-login
  //FIXME: DO BETTER CSS
  render() {
    const { errors } = this.state;

    return (
      <>
      <Helmet>
        <title>XING | Register</title>
      </Helmet>
      <Card className="register-form" title={this.getPageHeader("Register")} scrollToFirstError>
        <Form  layout="vertical" onFinish={(e) => {this.onSubmit(e)}}>

          <Form.Item label="Username" name="name" validateStatus={(errors.name) ? 'error' : 'success'} help={errors.name}>
            <Input onChange={this.onChange} placeholder="" />
          </Form.Item>

          <Form.Item name="email" label="Email" validateStatus={this.getEmailStatus()} help={this.getEmailErrors()}>
            <Input onChange={this.onChange} placeholder="" />
          </Form.Item>

          <Form.Item name="password" label="Password" validateStatus={this.getPasswordStatus()} help={this.getPasswordErrors()}>
            <Input.Password type="password" onChange={this.onChange} placeholder="" />
          </Form.Item>

          <Form.Item name="password2" label="Confirm Password" validateStatus={(errors.password2) ? 'error' : 'success'} help={errors.password2}>
            <Input.Password type="password" onChange={this.onChange} placeholder="" />
          </Form.Item>

          <Form.Item name="code" label="Authorization Code" validateStatus={(errors.code) ? 'error' : 'success'} help={errors.code}>
            <Input.Password type="password" onChange={this.onChange} placeholder="" />
          </Form.Item>

          <Form.Item name="agreement" valuePropName="checked" rules={[{
              validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject('You must accept the licence agreement'),
            },]}>
            <Checkbox>
                I accept the <a href="/tos">terms of service</a>
            </Checkbox>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Register
            </Button>
        </Form.Item>
        </Form>
      </Card>
      </>
    );
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { registerUser }
)(withRouter(Register));
