//https://blog.bitsrc.io/build-a-login-auth-app-with-mern-stack-part-2-frontend-6eac4e38ee82

import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING,
} from "./types";

import { ADD_NOTE, PUT_NOTE, DEL_NOTE, CLS_NOTE, LEA_NOTE } from "../../components/actions/noteActions" //FIXME: DO BETTER?

//FIXME: DO BETTER, VERIFY

const BUILD_URL = (process.env.NODE_ENV === 'production') ? "https://xing.api.conntinuity.org" : ""; //"https://conntinuity.org:5001"; //FIXME: DO BETTER

// Register User
export const registerUser = (userData, history) => dispatch => {
  axios
    .post(BUILD_URL + "/api/users/register", userData)
    .then(res => history.push("/login")) // re-direct to login on successful register
    .catch(err => {
      //console.log('error', err.response);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    });
};

export const setPassword = (userData, history) => dispatch => {
  axios
    .post(BUILD_URL + "/api/users/changepassword", userData)
    .then(res => history.push("/login")) // re-direct to login on successful register
    .catch(err => {
      //console.log('error', err.response);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    });
};

// Register User
export const sendRecoverPassword = (userData, history) => dispatch => {
  axios
    .post(BUILD_URL + "/api/users/sendrecoverpassword", userData)
    .then(res => history.push("/login")) // FIXME: DO BETTER
    .catch(err => {
      //console.log('error', err.response);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    });
};

// Login - get user token
export const loginUser = userData => dispatch => {
  axios
    .post(BUILD_URL + "/api/users/login", userData)
    .then(res => {
      // Save to localStorage

      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);

      // Set token to Auth header
      setAuthToken(token);

      // Decode token to get user data
      const decoded = jwt_decode(token);

      // Set current user
      dispatch(setCurrentUser(decoded));

      //Clear any lingering data //FIXME: ADD LINGERING TO DB?


      dispatch(getUserNotes()); //Don't need any params anymore.

    })
    .catch(err => {
      //console.log(err); //FIXME: INFORM USER OF RATE LIMITING?
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    });
};

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// User loading //FIXME: STUDY WHAT THIS IS
export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};

// Log user out
export const logoutUser = () => dispatch => {

  // Remove token from local storage
  localStorage.removeItem("jwtToken");

  // Remove auth header for future requests
  setAuthToken(false);

  //Remove user's notes
  dispatch({type:CLS_NOTE})

  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};

//MY OWN BELOW
// get user notes
// FIXME: RENAME TO RELOADNOTES?
// FIXME: REMOVE PARAMS?
export const getUserNotes = (userData) => dispatch => {

  dispatch({
    type:LEA_NOTE,
    areNotesLoading: true
  })

  

  axios.post(BUILD_URL + "/api/notes/listall", userData)
    .then(res => {
      dispatch({type:CLS_NOTE}); //Clear any old data
      //console.log('res', res);
      for(let note of res.data)
      {
        dispatch({
          type: ADD_NOTE,
          note
        })
      }

      dispatch({
        type:LEA_NOTE,
        areNotesLoading: false
      })

    }) //TODO
    .catch(err =>
      console.log(err)
    );
};

export const saveNote = (noteData) => dispatch => {
  //console.log('nd', noteData)
  axios.post(BUILD_URL + "/api/notes/add", noteData)
    .then(res => console.log(res)) // TODO
    .catch(err => {}
      //console.log(err) //FIXME: BETTER
    );
};

export const saveAddNote = (noteData, history) => dispatch => {
  //console.log('nd', noteData)
  axios.post(BUILD_URL + "/api/notes/add", noteData)
    .then(res => { //TODO
      //console.log(res); //FIXME: LIMIT WHAT DATA IS ADDED TO OUR STORE BY LIMITING RES?
      //FIXME: VERF=IFY
      const note = res.data
      dispatch({
        type: ADD_NOTE,
        note
      })

      //Pushes onto history that way back goes to add panel
      history.push(`/page/${note.uuid}`);

    }).catch(err => {}
      //console.log(err) //FIXME: BETTER
    );
};

export const savePutNote = (noteData, history) => dispatch => {
  // console.log('nd', noteData)
  axios.post(BUILD_URL + "/api/notes/put", noteData)
    .then(res => { //TODO
      // console.log('put res', res); //FIXME: LIMIT WHAT DATA IS ADDED TO OUR STORE BY LIMITING RES?

      const note = res.data;

      // console.log("DISPATCH TYPE: ", PUT_NOTE)
      dispatch({
        type: PUT_NOTE,
        note
      })

      // console.log("HIST", history);
      //Goes 'back' to page as thats the only way to access edit
      history.goBack()//.replace(`/page/${note.uuid}`);

    }).catch(err => {}
      //console.log(err) //FIXME: BETTER
    );
};

//FIXME: BETTER NAME SCHEME
export const saveDeleteNote = (uuid) => dispatch => {
  // console.log('nd', noteData)
  axios.post(BUILD_URL + "/api/notes/delete", {'uuid': uuid})
    .then(res => { //TODO
      // console.log('del res', res);
      dispatch({
        type: DEL_NOTE,
        uuid
      })

      // console.log("HIST", history);
      // history.push(`/page/${note.uuid}`);

    }).catch(err => {}
      //console.log(err) //FIXME: BETTER
    );
};
