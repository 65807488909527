import '../web.css';
import React from 'react';
import { Component } from 'react';

import { HashLink as Link } from 'react-router-hash-link';

import { Helmet } from "react-helmet";

import WebNav from './WebNav'
import WebFooter from './WebFooter'

import {
  SearchOutlined,
  PlusOutlined,
  MenuOutlined,
  ShrinkOutlined,
  EditOutlined
} from '@ant-design/icons';


class FAQPage extends Component {

  render() {
    return (
      <body className="webcontainer">
        <Helmet>
          <title>XING | FAQ</title>
        </Helmet>
        <WebNav url="/faq" />

        <section className="centeredcontent capcontentwidth">
          <h1>XING | FAQ</h1>
          <nav className="faq-grid">
            <Link to="/faq/addingnotes#" className="faq-card">
              <PlusOutlined />
              <p>Creating Notes</p>
            </Link>

            <Link to="/faq/searching#" className="faq-card">
              <SearchOutlined />
              <p>Searching Notes</p>
            </Link>

            <Link to="/faq/editing#" className="faq-card">
              <EditOutlined />
              <p>Editing Notes</p>
            </Link>
            <Link to="/faq/merging#" className="faq-card">
              <ShrinkOutlined />
              <p>Merging Notes</p>
            </Link>
          </nav>
        </section>


        <WebFooter />
      </body>
    )
  }
}


export default FAQPage;
