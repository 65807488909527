import '../web.css';
import React from 'react';
import { Component } from 'react';

import { HashLink as Link } from 'react-router-hash-link';

import {Helmet} from "react-helmet";

import WebNav from './WebNav'
import WebFooter from './WebFooter'

class FeaturesPage extends Component {

  render()
  {
    return (
      <body className="webcontainer">
        <Helmet>
          <title>XING | Features</title>
        </Helmet>
      <WebNav url="/features" />

      <section className="centeredcontent capcontentwidth">
  <h1>XING | Features</h1>
</section>

<section className="features-hero features-left-hero">
  <article className="features-article">
    <h2>Creating Notes</h2>
    <p>
      In XING, you can create notes and style them with markdown.
      While creating notes, XING will automatically search for similar notes that you
      have already written and display them on the right side of the screen.
      This allows you to organize your thoughts by keeping similar notes together.
    </p>
    <Link to="/faq/addingnotes#">Read more.</Link>
  </article>
  {' '}
  <img src="./web/xing-note-1.png" alt="Sample of XING UI for adding notes." width="1000" />
</section>

<section className="features-hero features-right-hero">
  <img src="./web/xing-search-1.png" alt="Sample of XING UI for searching notes." width="1000" />
  <article className="features-article">
    <h2>Searching Notes</h2>
    <p>
      To find your notes quickly, XING allows you to search for them by their
      title and tags—effectively becoming a personal search engine for your thoughts.
    </p>
    <Link to="/faq/searching#">Read more.</Link>
  </article>
</section>


<section className="features-hero features-left-hero">
  <article className="features-article">
    <h2>Editing Notes</h2>
    <p>
      After writing a note in XING, you can make changes to it at any time.
    </p>
    <Link to="/faq/editing#">Read more.</Link>
  </article>
  <img src="./web/xing-edit-1.png" alt="Sample of XING UI for editing notes." width="1000" />
</section>

<section className="features-hero features-right-hero">
  <img src="./web/xing-merge-1.png" alt="Sample of XING UI for merging notes." width="1000" />
  <article className="features-article">
    <h2>Merging Notes</h2>
    <p>
      While using XING, if you discover that two notes are about the same topic, you can
      merge them together to stay organized.
    </p>
    <Link to="/faq/merging#">Read more.</Link>
  </article>
</section>


      <WebFooter />
    </body>
    )
  }
}


export default FeaturesPage;
