/**
 * Used to represent ADD_NOTE in the local store
 * FIXME: Do better type (eg byte)
 * @type {String}
 */
export const ADD_NOTE = 'ADD_NOTE';

/**
 * Used to represent updating a note in the local store
 * FIXME: Do better type (eg byte)
 * FIXME: ADD the note if it doesnt exist?
 * @type {String}
 */
export const PUT_NOTE = 'PUT_NOTE';

/**
 * Used to represent DEL_NOTE in the local store
 * FIXME: Do better type (eg byte)
 * @type {String}
 */
export const DEL_NOTE = 'DEL_NOTE';

/**
 * Used to set the search bar text/search string
 * FIXME: Do better type (eg byte)
 * @type {String}
 */
export const SET_SEARCH = 'SET_SEARCH'; //FIXME: DO BETTER?


/**
 * Used to clear all notes in the local store. Used when refreshing with server info.
 * Used to clear all notes from the store (does not delete them). Used for reloading from DB.
 * FIXME: Do better type (eg byte)
 * @type {String}
 */
export const CLS_NOTE = 'CLS_NOTE';

/**
 * Used to set if we are currently loading notes or not. Used in the search panel to signal that we should
 * render skeleton notes. 
 * @type {String}
 */
export const LEA_NOTE = 'LEA_NOTE';


/**
 * Used as a shorthand to add a note to the local store
 * @param {[type]} note The note to be added to the store
 */
export const addNote = (note) => {
  return {
    type: ADD_NOTE,
    note
  }
}

/**
 * Used as a shorhand to dispacth updating a note's valus in the local store
 * @param  {[type]} note The note w/ its new values
 */
export const putNote = (note) => {
  return {
    type: PUT_NOTE,
    note
  }
}

/**
 * Used as a shorhand to dispacth a delete note
 * @param  {[type]} uuid The note's uuid
 */
export const delNote = (uuid) => {
  return {
    type: DEL_NOTE,
    uuid
  }
}

/**
 * Used as a shorthand to dispatch setting the search string
 * @param {[type]} search the new value to search for
 */
export const setSearch = (search) => {
  return {
    type: SET_SEARCH,
    search
  }
}

/**
 * Used as a shorthand to dispatch clearing all notes.
 */
export const clearNotes = () => {
  return {
    type: CLS_NOTE
  }
}
