import '../../web.css';
import React from 'react';
import { Component } from 'react';

import { Helmet } from "react-helmet";

import { HashLink as Link } from 'react-router-hash-link';

import WebNav from '../WebNav'
import WebFooter from '../WebFooter'


class SearchingFAQ extends Component {

  render() {
    return (
      <body className="webcontainer">
        <Helmet>
          <title>XING | FAQ - Searching Notes</title>
        </Helmet>
        <WebNav url="/faq/searching" />

        <section className="faqsection">
          <aside>
            <nav>
              <h3>XING | FAQ</h3>
              <ul>
                <li><Link to="/faq/addingnotes#">Creating Notes</Link></li>
                <li><Link className="active" to="/faq/searching#">Searching Notes</Link></li>
                <li><Link to="/faq/editing#">Editing Notes</Link></li>
                <li><Link to="/faq/merging#">Merging Notes</Link></li>
              </ul>
            </nav>
          </aside>
          <main className="faqcontent">
            <h1>XING | FAQ - Searching Notes</h1>
            <p>
              In XING, you can search for your notes in two ways: by text and by tag.
            </p>
            <h2>Searching by Text</h2>
            <p>When searching for a note by text, XING will automatically search for notes with similar titles and tags to your search. </p>
            <p>To search for a note by text:</p>
            <ol>
              <li>On the sidebar, click <span className="uitext">Search</span>.</li>
              <li>In the search bar, enter text contained in the note's title or tags.</li>
            </ol>
            <h2>Searching by Tags</h2>
            <p>When searching for a note by tags, XING will automatically build a search that looks for notes with a specific tag.</p>
            <p>To search for notes by tags: </p>
            <ol>
              <li>On the sidebar, click <span className="uitext">List</span>.</li>
              <li>At the top of the screen, click the <span className="uitext">Tags</span> tab.</li>
              <li>In the list below, click on the tag to search for.</li>
            </ol>
          </main>
        </section>




        <WebFooter />
      </body>
    )
  }
}


export default SearchingFAQ;
