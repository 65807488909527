import '../web.css';
import React from 'react';
import { Component } from 'react';


class WebFooter extends Component {

  render()
  {
    return (
      <footer>
    		<p>XING ©  Alex Friedman 2021</p>
    	</footer>
    )
  }
}


export default WebFooter;
