import { ADD_NOTE, PUT_NOTE, DEL_NOTE, SET_SEARCH, CLS_NOTE, LEA_NOTE } from '../actions/noteActions'

//FIXME: DO BETTER, ESPECIALLY WITH UUIDS
/**
 * Used as an iniial state. FIXME: REMOVE DEFAULTS?
 */
const initState = {
  notes:[
    {
      "title":"Note One",
      "tags":["tag1", "tag3"],
      "body":"This is the first sample note!",
      "uuid":'0'
    },
    {
      "title":"Note Two",
      "tags":["tag2"],
      "body":"This is the second sample note!",
      "uuid":'1'
    },
  ],
  lastSearch: "",
  areNotesLoading: true
}

//Called by our dispatch calls.
const noteReducer = (state = initState, action)=>{ //FIXME: STUDY

    switch (action.type)
    {
      case LEA_NOTE:
      return {
        ...state,
        areNotesLoading: action.areNotesLoading
      }
      default:
      //FIXME: DO BETTER
    }
    if(action.type === ADD_NOTE) //FIXME: CASE SWITCH
    {
      //Add a note to the store
      return {
        ...state,
        notes:  [...state.notes, action.note]
      }
      //state.notes = [...state.notes, action.note] //FIXME: CHECK
    }
    else if(action.type === PUT_NOTE)
    {
      //FIXME: RESTRUCTURE DATA AS HASHSET W/ UUID
      //try to find the note, and update it
      for(let i in state.notes)
      {
        if(state.notes[i].uuid === action.note.uuid)
        {
          state.notes[i] = action.note;
          break; //Make sure we don't somehow duplicate
        }
      }
    }
    else if(action.type === DEL_NOTE)
    {
      //Delete a note.
      return {
        ...state,
        notes: state.notes.filter(note => note.uuid !== action.uuid) //FIXME: DO BETTER b/c UUID SHOULD BE UNIQUE
      }
    }
    else if(action.type === CLS_NOTE)
    {
      //Clear our notes
      return {
        ...state,
        notes: []
      }
    }
    else if(action.type === SET_SEARCH)
    {
      //Set the search text
      return {
        ...state,
        lastSearch: action.search
      }
    }
    // console.log(state)
    return state;
}

export default noteReducer;
