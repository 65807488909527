//https://blog.bitsrc.io/build-a-login-auth-app-with-the-mern-stack-part-3-react-components-88190f8db718

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser, getUserNotes } from "./actions/authActions";

import {Card, PageHeader, Avatar, Button } from "antd";
import { withRouter } from 'react-router-dom';

import { LogoutOutlined, SyncOutlined } from '@ant-design/icons';

class Dashboard extends Component {
  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };

  onSyncClick = e => {
    e.preventDefault();
    //
    // let query = {
    //   ownerId: this.props.auth.user.id
    // }

    this.props.getUserNotes();//query);
  };

  //FIXME: VERIFY CSS
  getPageHeader = (name) => {
    return (
      <PageHeader className="note-header" onBack={() => {this.props.history.goBack()}} title={name} />
    )
  }

  render() {
    const { user } = this.props.auth;
    return (
      <>
      <Card title={this.getPageHeader("User Dashboard")}>
          <Card style={{float:'left', display:'flex', flexDirection:'column', alignItems:'center'}} bodyStyle={{float:'left', display:'flex', flexDirection:'column', alignItems:'center'}}>
          <div style={{display:'block', marginBottom:'35px'}}>
          <Avatar style={{float:'left', marginRight:'40px'}} size={60}> {user.name.charAt(0)}</Avatar>
          <div style={{float:'right'}}>
            <span style={{fontSize:'20px', verticalAlign:'top'}}>{user.name} </span><br />
            <span>Email: {user.email}</span>
          </div>
          </div>

          <Button style={{marginBottom:'5px'}}type="primary" shape="round" icon={<LogoutOutlined />} onClick={this.onLogoutClick}>
            Log out
          </Button>


          <Button type="primary" shape="round" icon={<SyncOutlined />} onClick={this.onSyncClick}>
            Synchronize
          </Button>
        </Card>

      </Card>
      </>
    )
    /*
    return (
      <div style={{ height: "75vh" }} className="container valign-wrapper">
        <div className="row">
          <div className="col s12 center-align">
            <h4>
              <p>{JSON.stringify(this.props.auth)}</p>
              <b>Hey there,</b> {user.name.split(" ")[0]}
              <p className="flow-text grey-text text-darken-1">
                You are logged into a full-stack{" "}
                <span style={{ fontFamily: "monospace" }}>MERN</span> app
              </p>
            </h4>
            <button
              style={{
                width: "150px",
                borderRadius: "3px",
                letterSpacing: "1.5px",
                marginTop: "1rem"
              }}
              onClick={this.onLogoutClick}
              className="btn btn-large waves-effect waves-light hoverable blue accent-3"
            >
              Logout
            </button>

            <button
              style={{
                width: "150px",
                borderRadius: "3px",
                letterSpacing: "1.5px",
                marginTop: "1rem"
              }}
              onClick={this.onTestClick}
              className="btn btn-large waves-effect waves-light hoverable blue accent-3"
            >
              Who knows? lol!
            </button>
          </div>
        </div>
      </div>
    );
    */
  }
}

//FIXME WHAT?
Dashboard.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(connect(mapStateToProps, { logoutUser, getUserNotes })(Dashboard));
