import '../web.css';
import React from 'react';

import { HashLink as Link } from 'react-router-hash-link';

import { useState, useEffect } from 'react';

import { useSelector } from "react-redux";

import {
  MenuOutlined
} from '@ant-design/icons';

function WebNav(props) {

  const getClassName = (id) => {

    //If we don't have any props, then just default to home
    if (!props.url)
      return (id === '/') ? 'active' : '';

    if (props.url.startsWith('/faq/'))
      return (id === '/faq' || id === props.url) ? 'active' : '';

    //FIXME: merge w/ faq?
    return (id === props.url) ? 'active' : ''
  }

  const auth = useSelector(state => state.auth);

  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    handleResize(); //Used to make sure that we update on load
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [])

  const handleResize = () => {
    setOpen(window.innerWidth <= 800)
  }


  return (
    <header className="navheader">
      <nav id="topnav" className="navbar">
        <Link className={getClassName('/')} to="/#"><img className="logoImage" src="/logo192.png" alt="XING logo." /></Link>
        {' '}
        <Link className={getClassName('/about')} to="/about#">About</Link>
        {' '}
        <Link className={getClassName('/features')} to="/features#">Features</Link>
        {' '}
        <div className="subnav">
          <Link className={getClassName('/faq')} to="/faq#">FAQ</Link>
          {' '}
          <nav className="subnav-content">
            <Link className={getClassName('/faq/addingnotes')} to="/faq/addingnotes#">Creating Notes</Link>
            {' '}
            <Link className={getClassName('/faq/searching')} to="/faq/searching#">Searching Notes</Link>
            {' '}
            <Link className={getClassName('/faq/editing')} to="/faq/editing#">Editing Notes</Link>
            {' '}
            <Link className={getClassName('/faq/merging')} to="/faq/merging#">Merging Notes</Link>
            {' '}
          </nav>
        </div>
        <Link className={getClassName('/updates')} to="/updates#">Updates</Link>
        {' '}
        {isOpen && !auth.isAuthenticated &&
          <Link to="/login">Login</Link>
        }
        {
          isOpen && auth.isAuthenticated && <Link to="/list">Open XING</Link>
        }
        {' '}
        <nav className="navright loginnav">
          { !auth.isAuthenticated &&
          (<><Link to="/register">Register</Link>
          {' '}</>)
          }
          <Link to="/login">{auth.isAuthenticated ? 'Open XING' : 'Login'}</Link>
        </nav>
        <nav className="navright">
          <a onClick={() => {
            let navbar = document.getElementById("topnav");
            if (navbar.className === "navbar") {
              navbar.className += " responsive";
            }
            else {
              navbar.className = "navbar";
            }
          }}><MenuOutlined /></a>
        </nav>
      </nav>
    </header>

  )
}


export default WebNav;
