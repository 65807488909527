import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { addNote, setSearch } from '../actions/noteActions'
import { withRouter } from 'react-router-dom';

import { Tabs, List, Card, Statistic, Skeleton } from 'antd';

import NoteComponent from '../functional/NoteComponent'


const { TabPane } = Tabs;



/**
 * Used to copy the props in the store that we need to reference
 * to also be in this class
 *
 * FIXME: Review this more
 */
const mapStateToProps = (state) => {
  return {
        notes: state.notes || state.note.notes,
        areNotesLoading: state.note.areNotesLoading
      }
}


/**
 * Used to map the functions we can dispatch to be callable from this
 * class.
 */
const mapDispatchToProps= (dispatch)=>{
    return{
        addNote: (note) => { dispatch(addNote(note)) }, //FIXME: DO BETTER, RENAME, REM
        setSearch: (search) => { dispatch(setSearch(search)) } //FIXME: DO BETTER, RENAME
    }
}

/**
 * Used to display the list of all notes or all tags
 */
class ListPanel extends Component {

  //FIXME: ADD TAG CLICK/SEARCH? -> should be done?

  //On note click, navigate to that page
  noteClick = (note) => {
    //this.props.addNote(note); //FIXME: CHANGE
    this.props.history.push(`/page/${note.uuid}`);
  }

  //Used to get the list of notes.
  getNotesList = () => {
    let notesList = (this.props.areNotesLoading) ? <Card className="full-note-card"><Skeleton active /></Card> : this.props.notes.map(note=>{

      return (
        <NoteComponent key={note.uuid} note={note} onTagClick={(e, tag) => {
          this.props.setSearch(tag);
          e.stopPropagation();
          this.props.history.push(`/`);
        }}
        noteClicked={(note) => {
          this.noteClick(note);
        }} />
      )
    })
    return notesList;
  }

  //Used to get the list of all tags
  getTagsList = () => {
    /*
    let allTags = this.props.notes.map(note=>{

    })
    */
   let allTags = this.props.notes.reduce(
     (accumulator, curr) => accumulator.concat(curr.tags), []) //Flatten all tags into an array
     .reduce((accumulator, curr) => {
       if(curr in accumulator)
          accumulator[curr]++;
        else
          accumulator[curr] = 1;
        return accumulator;
     }, {}) //Deduplication


    allTags = Object.keys(allTags).reduce((a, curr)=>
      a.concat({name:curr, count:allTags[curr]})
    , [])
   /*
   for(let note of this.props.notes)
   {
     allTags = [
       ...allTags,
       ...note.tags
     ]
   }
   */


   // console.log('at', allTags);
   //FIXME: DO BETTER TAG SEARCH EVENTUALLY (EG tag:<TAG>)
    return (
      <Card style={{width:'85%', display:'block', marginLeft:'auto', marginRight:'auto'}}>
        <List loading={this.props.areNotesLoading} style={{'flex':'1 0 0', overflowY:'auto'}} dataSource={allTags} renderItem={item => (
        <List.Item key={item.name}>
          <Card hoverable size="small" bordered={false} style={{width:'100%', padding:'0px', margin:'0px'}} onClick={()=>{
            this.props.setSearch(item.name);
            this.props.history.push(`/`); //Set to search page
          }} bodyStyle={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
          <h1 style={{'flex':'1'}}>{item.name}</h1> <Statistic style={{}} title="Count" value={item.count} />
          </Card>
        </List.Item>
      )}>
      </List>
      </Card>
    )
  }

  render() {
    return (
    <Tabs className="tabList" style={{'flex':'1 1 auto', marginRight:'-16px', marginLeft:'-16px'}} defaultActiveKey="1" centered>
      <TabPane style={{ 'flex':'1 1 auto', 'overflow':'auto'}} tab="Notes" key="1">
        {this.getNotesList()}
      </TabPane>
      <TabPane  style={{ 'flex':'1 1 auto', 'overflow':'auto'}} tab="Tags" key="2">
        {this.getTagsList()}
      </TabPane>
    </Tabs>
  )
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListPanel)); //FIXME: REVIEW!
