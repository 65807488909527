import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { addNote } from '../actions/noteActions'
import { withRouter } from 'react-router-dom';

import { EditOutlined } from '@ant-design/icons';

import { Card, Tag, Button, Tooltip, List, Row, Col, PageHeader } from 'antd';
import ReactMarkdown from 'react-markdown'

import {noteSuggestions} from '../../util/SearchUtils';

import InfoTooltip from '../functional/InfoTooltip'

import { Link } from 'react-router-dom';

//https://github.com/remarkjs/react-markdown/issues/65 Make links open in new tags
//https://github.com/remarkjs/react-markdown //FIXME: ADD EVERYWHERE
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {vs} from 'react-syntax-highlighter/dist/esm/styles/prism'
const renderers = {
  code: ({language, value}) => {
    return <SyntaxHighlighter style={vs} language={language} children={value} />
  },
  link: (props) => {
    return <a href={props.href} target="_blank">{props.children}</a>
  }
}


/**
 * Used to copy the props in the store that we need to reference
 * to also be in this class
 *
 * FIXME: Review this more
 */
const mapStateToProps = (state) => {
  return {
        notes: state.notes || state.note.notes
      }
}

/**
 * Used to map the functions we can dispatch to be callable from this
 * class.
 */
const mapDispatchToProps= (dispatch)=>{
    return{
        addNote: (note) => { dispatch(addNote(note)) } //FIXME: DO BETTER, RENAME
    }
}


/**
 * Used to display a whole note
 */
class PagePanel extends Component {

  constructor(props) {
     super(props);
     this.updatePage(); //Set the current page
  }

  //Used to set the page to correspond to the URL
  updatePage = () => {
    this.pageid = this.props.match.params.pageid;

    for(let pg of this.props.notes)
     if(this.pageid === pg.uuid) //FIXME: USE THIS SORT OF THING MORE
     {
         this.currentNote = pg;
         break;
     }
     //console.log(this.currentNote);
  }


  handleClick = (note) => {
    //this.props.addNote(note); //FIXME: CHANGE
  }

  //Used to get the button to edit the note
  getEditButton = () => {
    //Old button which manually changes link. //FIXME: USE <Link> instead of history
    /*
    return (
      <Tooltip title="edit">
        <Button shape="circle" type="link" icon={<EditOutlined />} onClick={() =>{
          this.props.history.push(`/edit/${this.currentNote.uuid}/`);
        }} />
      </Tooltip>
    )
    */

   return (
     <Tooltip title="edit">
       <Link to={`/edit/${this.currentNote.uuid}/`}>
         <Button shape="circle" type="link" icon={<EditOutlined />} />
       </Link>
     </Tooltip>
   )

  }


  //Used to get a list of similar notes
  getSimilarNotes = () => {

    if(this.currentNote == null)
      return; //FIXME: DO BETTER
    let tags = this.currentNote.tags;
    let title = this.currentNote.title;

    //FIXME: PREVENT CURRENT NOTE FROM SHOWING AS SIMILAR
    return (
      <>
      <List style={{'flex':'1 0 0', overflowY:'auto'}}dataSource={noteSuggestions(this.props.notes, title, tags, this.currentNote.uuid)} renderItem={item => (
        <List.Item key={item.uuid}>
          <Card hoverable size="small" bordered={false} style={{width:'100%', padding:'0px', margin:'0px'}} onClick={() =>{
            this.props.history.push(`/page/${item.uuid}/`);
          }}>
          {item.title}
          </Card>
        </List.Item>
      )}>
      </List>
      </>
    )
  }

//FIXME: RENAME, ALSO IN ADD PANEL
  getTooltipTitle = () => {
    return (
    <div>
    Browse Similar Notes <InfoTooltip text="Similar notes are listed below. Click on one of them to view it."/>
    </div>
    )
  }

  getPageHeader = (name) => {
    return (
      <PageHeader className="note-header" onBack={() => {this.props.history.goBack()}} title={name} subTitle="" />
    )
  }

  //FIXME: MAKE CALL FOR ONLY ONE PAGE
  //FIXME: CAN SHOW UP AS UNDER ADD TAB
  render() {

    // console.log('reeender');
    // console.log(this.props.match.params.pageid);
    //FIXME: DO A CHECK HERE, OR IS THERE NO REASON TO? IS THE ONLY REASON FOR AN UPDATE A PAGE REFRESH?
    this.updatePage(); //Make sure we match the page
    // console.log(this.pageid);
    if(this.currentNote == null)
      return (<p>404</p>); //FIXME: DO BETTER

    // let notesList = this.props.notes.filter(note => note.uuid === this.pageid).map(note => {

      let tagsList = this.currentNote.tags.map(tag=>{
        return (
          <Tag key={tag}>
            {tag}
          </Tag>
        )
      })

    // })

    /*
    return (
      <div style={{height:'100%'}}>
        <div style={{height:'100%'}}>
          {notesList}
        </div>
      </div>
    )
    */
   return (
     <>
       <Row gutter={[16, 0]} style={{'flex':'1 1 auto', marginBottom:'0px'}}>
           <Col span={14} style={{'display':'flex'}}>
              <Card className="page-note-card" title={this.getPageHeader(this.currentNote.title)} extra={this.getEditButton()} onClick={() => { this.handleClick(this.currentNote) } } style={{ overflow:'auto', height:'100%'}}>
                <div style={{'margin-bottom':'16px'}}>{tagsList}</div>
                <div style={{flex:'1 1 0', overflow:'auto'}}>
                  <ReactMarkdown  renderers={renderers} >{this.currentNote.body}</ReactMarkdown>
                </div>
              </Card>
           </Col>
           <Col span={10} style={{'display':'flex'}}>
            <Card title={this.getTooltipTitle()} style={{width:"100%", display:'flex', flexDirection:'column'}} bodyStyle={{display:'flex', 'flex-direction':'column', flex:'1 1 auto'}}>
               {this.getSimilarNotes()}
            </Card>
           </Col>
       </Row>
       </>
   )
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PagePanel)); //FIXME: REVIEW!
