import '../../web.css';
import React from 'react';
import { Component } from 'react';

import { Helmet } from "react-helmet";

import { HashLink as Link } from 'react-router-hash-link';

import WebNav from '../WebNav'
import WebFooter from '../WebFooter'

class EditingFAQ extends Component {

	render() {
		return (
			<body className="webcontainer">
				<Helmet>
					<title>XING | FAQ - Editing Notes</title>
				</Helmet>
				<WebNav url="/faq/editing" />

				<section className="faqsection">
					<aside>
						<nav>
							<h3>XING | FAQ</h3>
							<ul>
								<li><Link to="/faq/addingnotes#">Creating Notes</Link></li>
								<li><Link to="/faq/searching#">Searching Notes</Link></li>
								<li><Link className="active" to="/faq/editing#">Editing Notes</Link></li>
								<li><Link to="/faq/merging#">Merging Notes</Link></li>
							</ul>
						</nav>
					</aside>
					<main className="faqcontent">
						<h1>XING | FAQ - Editing Notes</h1>
						<p>
							After writing a note in XING, you can make changes to it at any time.
						</p>
						<h2>To Edit a Note:</h2>
						<ol>
							<li>
								Do one of the following to open the <span className="uitext">Edit Note</span> menu:
								<ul>
									<li>From an open note, click the edit button.</li>
									<li>From the <span className="uitext">Search</span> or <span className="uitext">List</span> page, hover your mouse over the note to edit, and click the edit button. </li>
									<div className="note"><em>Note: The edit button is represented by a pencil icon and is located to the right side of the note's title.</em></div>
								</ul>
							</li>
							<li>In the <span className="uitext">Edit Note</span> form, make the desired edits to the note's title, tags, and content.</li>
							<li>Click <span className="uitext">Submit</span>.</li>
						</ol>
					</main>
				</section>
				<WebFooter />
			</body>
		)
	}
}


export default EditingFAQ;
