//https://blog.bitsrc.io/build-a-login-auth-app-with-mern-stack-part-2-frontend-6eac4e38ee82

export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";


//My own below
export const GET_USER_NOTES = "GET_UN";
export const SAVE_NOTE = "SAVE_NOTE";
