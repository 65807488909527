//https://blog.bitsrc.io/build-a-login-auth-app-with-the-mern-stack-part-3-react-components-88190f8db718

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';

//FIXME: DO BETTER!
import {Helmet} from "react-helmet";

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      auth.isAuthenticated === true ? (
        <>
        <Helmet>
          <title>XING</title>
        </Helmet>
        <Component {...props} />
        </>
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

//FIXME: CLEAN UP?
const mapStateToProps = state => ({
  auth: state.auth,
  notes: state.notes || state.note.notes,
  lastSearch: state.lastSearch || state.note.lastSearch
});

export default withRouter(connect(mapStateToProps)(PrivateRoute));
